<template>
  <!-- 货品添加弹窗-->
  <div class="newOrderGoodsAdd">
    <div class="flexGrow1 flex marginBottom10">
      <div class="hHP w200 flex directionColumn">
        <el-tabs v-model="tabs">
          <el-tab-pane label="货品分类" name="1" />
          <el-tab-pane label="货品标签" name="2" />
        </el-tabs>
        <el-input v-model="filterText" class="wHP" placeholder="输入关键字" />
        <div class="marginTop10 h450 scrollbar">
          <el-tree v-show="tabs === '1'" ref="tree" show-checkbox class="filter-tree" :data="sortsData" :props="defaultProps" default-expand-all :filter-node-method="filterNode" @check-change="handleCheckChange" />
        </div>
      </div>
      <div class="marginLeft20 w800 paddingTop10 flexGrow1 flex">
        <div class="wFP flex directionColumn">
          <el-input v-model="search" placeholder="请输入内容" clearable class="w300" @change="queryPageStoreGoods">
            <el-button slot="append" icon="el-icon-search" @click="queryPageStoreGoods"></el-button>
          </el-input>
          <div class="marginTop10 bcGrey padding5 borderRadius5 paddingLeft20">当前选中 {{ tableData.length }} 项 <span class="pointer blue" @click="tableData = []">取消选择</span></div>
          <div v-infinite-scroll="concatGoodsData" class="marginTop10 flexGrow1 scrollbar scrollAuto h400">
            <el-row>
              <el-checkbox-group v-model="tableData">
                <el-col v-for="goods in goodsData" :key="goods.c_barcode" :span="6">
                  <div class="border margin5 flex directionColumn" style="height: 115px">
                    <el-popover placement="bottom" trigger="hover">
                      <div class="w250 flex">
                        <div class="w60 paddingRight10 textCenter">
                          <el-image slot="reference" :src="goods.c_master_url" class="w50 h50" fit="fit" />
                          <div class="grey fs11">零售价</div>
                          <div class="fs11">{{ goods.n_price }}</div>
                        </div>
                        <div>
                          <a class="fs11 blue"> {{ goods.c_name }}</a>
                          <div class="fs11">{{ goods.c_barcode }}</div>
                          <div class="marginTop10 grey fs11">
                            货品库存 <span class="fs11 black">{{ goods.n_total }}</span>
                          </div>
                          <div class="grey fs11">
                            可用库存 <span class="fs11 black">{{ goods.n_surplus }}</span>
                          </div>
                          <div class="grey fs11">
                            货品库位 <span class="fs11 black">{{ goods.c_num }}</span>
                          </div>
                          <div class="grey fs11">
                            货品规格 <span class="fs11 black">{{ goods.c_specification }}</span>
                          </div>
                        </div>
                      </div>
                      <el-checkbox slot="reference" class="marginLeft5" :label="goods">{{ goods.c_barcode }}</el-checkbox>
                    </el-popover>
                    <div class="flexGrow1">
                      <el-image :preview-src-list="[goods.c_master_url]" :src="goods.c_master_url" class="hHP wHP" fit="fit" />
                    </div>
                  </div>
                </el-col>
              </el-checkbox-group>
            </el-row>
          </div>
        </div>
        <div class="wFP paddingLeft20">
          <el-table ref="multipleTable" :data="tableData" class="popper-40 wHP" tooltip-effect="dark" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="40" />
            <el-table-column align="center" label="SKU" width="50">
              <template slot-scope="scope">
                <el-popover ref="myPopover" placement="bottom-start" width="355" trigger="click">
                  <div class="flex directionColumn">
                    <div class="flex" style="justify-content: space-between; align-items: center">
                      <el-tabs v-model="activeName">
                        <el-tab-pane label="近似品" name="1" />
                        <el-tab-pane label="关联品" name="2" />
                      </el-tabs>
                      <a class="blue pointer" @click="handleSubmit">提交</a>
                    </div>
                    <div class="h50 flex scrollAutoX" style="white-space: nowrap">
                      <div>123</div>
                      <div>123</div>
                      <div>123</div>
                    </div>
                  </div>
                  <div slot="reference" class="pointer">
                    {{ scope.row.c_barcode }}
                  </div>
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column align="center" label="零售价" width="50">
              <template slot-scope="scope">{{ scope.row.n_price }}</template>
            </el-table-column>
            <el-table-column align="center" label="库位号" width="60">
              <template slot-scope="scope">{{ scope.row.c_num }}</template>
            </el-table-column>
            <el-table-column align="center" label="数量">
              <template slot-scope="scope">
                <el-input-number v-if="maxNum" v-model="scope.row.num" :max="scope.row.n_surplus" :min="0" controls-position="right" />
                <el-input-number v-else v-model="scope.row.num" :min="0" controls-position="right" />
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作" width="60">
              <template #default="scope">
                <i class="el-icon-delete pointer" @click="onDelete(scope.$index)"></i>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <el-row type="flex" justify="end">
      <el-button type="primary" plain @click="onCancel(true)">取 消</el-button>
      <el-button type="primary" @click="onSubmit">确 认</el-button>
    </el-row>
  </div>
</template>

<script>
import { topsUtils } from "@/utils/topsUtils";
import { deleteGoodsById, exportGoods, goodsList, goodsSortList, handleTree, importGoodsTemplate, selectDictLabel } from "../../api/goods/goods";

export default {
  name: "OrderGoodsAdd",
  components: {
    // NoData,
  },
  props: {
    selectStore: {
      type: Object,
      default: () => {},
    },
    goods: {
      type: [String, Object, Array], // 根据需要指定数据类型
      default: "", // 设置一个默认值，如空字符串
    },
    maxNum: {
      type: Boolean,
      default: null, // 设置一个默认值，如空字符串
    },
  },
  data() {
    return {
      tabs: "1",
      activeName: "1",
      //树形图
      filterText: "",
      sortsData: [],
      sortsList: [],
      defaultProps: {
        children: "children",
        label: "ssort",
      },
      selectIds: [],
      // 商品列表
      goodsData: [],
      // 搜索条件
      search: "",
      queryParams: {
        currentPage: 1,
        pageSize: 20,
        sortIds: "",
      },
      //选中列表
      tableData: [],
      //近似品
      similarList: [],
      //关联品
      relevanceList: [],
    };
  },
  computed: {
    topsUtils() {
      return topsUtils;
    },
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  created() {
    this.fetchGoodSorts();
    this.queryPageStoreGoods();
    if (this.goods.length > 0) {
      this.tableData = this.goods;
    }
    // this.selectGoodsSKU();
  },
  methods: {
    /**
     * 关闭近似弹窗
     */
    handleSubmit() {
      this.$refs.myPopover.hide();
    },
    /**
     * 获取库存信息
     */
    queryPageStoreGoods() {
      let url = `${this.baseUrls.store}/queryPageStoreGoods`;
      this.queryParams.currentPage = 1;
      let params = this.queryParams;
      params.storeId = this.selectStore.rstore;
      params.search = this.search;
      this.topsAjax.get(url, params).then((res) => {
        this.goodsData = res.list;
      });
    },
    /**
     * 下拉刷新库存信息
     */
    concatGoodsData() {
      let url = `${this.baseUrls.store}/queryPageStoreGoods`;
      this.queryParams.currentPage += 1;
      let params = this.queryParams;
      params.storeId = this.selectStore.rstore;
      params.search = this.search;
      this.topsAjax.get(url, params).then((res) => {
        this.goodsData = this.goodsData.concat(res.list);
      });
    },
    /**
     * 获取分类树
     */
    fetchGoodSorts() {
      goodsSortList().then((response) => {
        this.sortsList = response.data;
        this.sortsData = handleTree(response.data, "sid", "rsort");
      });
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.ssort.indexOf(value) !== -1;
    },
    handleCheckChange(data, checked) {
      if (checked) {
        this.selectIds.push(data.sid);
      } else {
        const index = this.selectIds.indexOf(data.sid);
        if (index > -1) {
          this.selectIds.splice(index, 1);
        }
      }
      this.queryParams.sortIds = this.selectIds.join(",");
      this.queryPageStoreGoods();
    },
    //old
    //old
    //old
    //old
    //old
    //old
    //old
    //old
    selectSimilar() {
      if (this.filterText === "" || this.filterText === null || this.filterText === undefined) {
        this.similarData = this.goodsList.filter((item) => item.r_sort === this.checkGoods.r_sort && item.r_goods !== this.checkGoods.r_goods);
      } else {
        this.similarData = this.similarData.filter((item) => item.name.includes(this.filterText) || item.barcode.includes(this.filterText));
      }
    },
    /**
     * 替換商品
     */
    replace() {
      this.replaceGoods.masterUrl = this.replaceGoods.c_master_url;
      this.tableData[this.checkGoodsIndex] = this.replaceGoods;
      this.handleClose();
    },
    /**
     * 关闭近似商品
     */
    handleClose() {
      this.dialogVisible = false;
      this.checkGoods = "";
      this.similarData = [];
      this.replaceGoods = "";
      this.$refs.multipleTable.clearSelection();
    },
    /**
     * 打开近似商品
     */
    openSimilar() {
      if (!this.checkGoods.sid) {
        this.$message.error("请选择一种商品");
        return;
      } else {
        this.similarData = this.goodsList.filter((item) => item.r_sort === this.checkGoods.r_sort && item.r_goods !== this.checkGoods.r_goods);
        this.dialogVisible = true;
      }
    },
    /**
     * 选中近似商品
     * @param val
     */
    onChangeSimilarData(val) {
      if (val.length === 0) {
        this.replaceGoods = "";
      } else if (val.length === 1) {
        this.replaceGoods = val[0];
      } else {
        this.$refs.similarTable.clearSelection();
        this.$refs.similarTable.toggleRowSelection(val[val.length - 1]);
      }
    },
    /**
     * 选中要替换的商品
     * @param val
     */
    handleSelectionChange(val) {
      if (val.length === 0) {
        this.checkGoodsIndex = "";
        this.checkGoods = "";
      } else if (val.length === 1) {
        this.checkGoodsIndex = this.tableData.findIndex((data) => data === val[0]);
        this.checkGoods = val[0];
      } else {
        this.$refs.multipleTable.clearSelection();
        this.$refs.multipleTable.toggleRowSelection(val[val.length - 1]);
      }
    },
    /**
     * 如果为修改订单匹配商品
     */
    matchingGoods() {
      this.tableData.forEach((item) => {
        if (item.c_barcode) {
          let goods = this.goodsList.find((goods) => goods.c_barcode === item.c_barcode);
          item.warehouse = goods.warehouse;
          item.surplus = goods.surplus;
        }
      });
    },
    /**
     * 输入后新增
     * @param row
     */
    handleBlur(row) {
      let item = this.tableData[this.tableData.length - 1];
      if (row.num) {
        if (item?.barcode && item?.num) {
          this.tableData.push({});
        }
      }
      // if (row.nSurplus) {
      //   if (item?.rGoods && item?.rWarehouse && item?.nSurplus) {
      //     this.tableData.push({});
      //   }
      // }
    },
    /**
     * 刪除
     * @param index
     */
    onDelete(index) {
      this.tableData.splice(index, 1);
    },
    /**
     * 获取商品barcode
     */
    selectGoodsSKU() {
      const url = `${this.baseUrls.store}/queryStoreGoods`;
      this.topsAjax.get(url, { storeId: this.selectStore.rstore }).then((res) => {
        this.goodsList = res;
        if (this.selectStore.sid) {
          this.matchingGoods();
        }
      });
    },
    /**
     * 选择SKU
     * @param row
     * @param selectedItem
     */
    onChangeSKU(row, selectedItem) {
      let flag = false;
      this.tableData.forEach((p) => {
        if (p.sid === selectedItem.sid) {
          flag = true;
          delete row.barcode;
          return;
        }
      });
      if (flag) {
        this.$message.error(`商品重复`);
        return;
      }
      row.sid = selectedItem.sid;
      row.warehouse = selectedItem.warehouse;
      row.surplus = selectedItem.surplus;
      row.name = selectedItem.name;
      row.barcode = selectedItem.barcode;
      row.price = selectedItem.price;
      row.r_goods = selectedItem.r_goods;
      row.c_barcode_url = selectedItem.c_barcode_url;
      row.c_code = selectedItem.c_code;
      row.c_name = selectedItem.c_name;
      row.c_barcode = selectedItem.c_barcode;
      row.c_specification = selectedItem.c_specification;
      row.n_period = selectedItem.n_period;
      row.n_price = selectedItem.n_price;
      row.price = selectedItem.price;
      row.r_brand = selectedItem.r_brand;
      row.r_sort = selectedItem.r_sort;
      row.s_unit = selectedItem.s_unit;
      row.masterUrl = selectedItem.c_master_url;
    },
    handleInput(row) {
      if (parseFloat(row.num) > row.surplus) {
        this.$alert(`数量不得大于 ${row.surplus}`, "提示", {
          confirmButtonText: "确定",
        });
        row.num = row.surplus;
      }
    },
    onCancel(value) {
      this.$emit("OrderGoodsAdd", value ? [] : this.tableData);
    },
    onSubmit() {
      let newArray = this.tableData;
      let flag = false;
      let skuMap = {}; // 用来记录每个 SKU 的出现次数
      newArray.forEach((p) => {
        if (p.num === undefined || p.num === "" || p.num < 1) {
          flag = true;
          this.$message.error(`商品出库数至少要为1！`);
        }
        // 检查 SKU 是否重复
        if (skuMap[p.c_barcode]) {
          flag = true;
          this.$message.error(`商品 SKU ${p.c_barcode} 存在重复！`);
        } else {
          skuMap[p.c_barcode] = true;
        }
      });
      if (flag) return;
      this.onCancel();
    },
  },
};
</script>

<style lang="scss" scoped>
.newOrderGoodsAdd {
  height: 600px;
  background-color: white;
  display: flex;
  flex-direction: column;

  .el-input-number--medium {
    width: 80px;
  }

  .el-tabs__item {
    padding: 0 10px;
  }

  .el-tabs__item.is-active {
    text-decoration: underline;
  }

  .el-input {
    width: 80px;
  }

  .el-popper[x-placement^="bottom"] {
    margin-left: -40px;
  }
}

//.orderGoodsAdd {
//  height: 500px;
//  background-color: white;
//  display: flex;
//  flex-direction: column;
//
//  &__top {
//    flex: 1;
//  }
//
//  &__bottom {
//    height: 50px;
//    line-height: 50px;
//    text-align: right;
//  }
//
//  .el-input {
//    width: 120px;
//  }
//
//  .cell {
//    & > div {
//      width: 120px;
//    }
//  }
//}
</style>
