var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "newOrderGoodsAdd" },
    [
      _c("div", { staticClass: "flexGrow1 flex marginBottom10" }, [
        _c(
          "div",
          { staticClass: "hHP w200 flex directionColumn" },
          [
            _c(
              "el-tabs",
              {
                model: {
                  value: _vm.tabs,
                  callback: function ($$v) {
                    _vm.tabs = $$v
                  },
                  expression: "tabs",
                },
              },
              [
                _c("el-tab-pane", { attrs: { label: "货品分类", name: "1" } }),
                _c("el-tab-pane", { attrs: { label: "货品标签", name: "2" } }),
              ],
              1
            ),
            _c("el-input", {
              staticClass: "wHP",
              attrs: { placeholder: "输入关键字" },
              model: {
                value: _vm.filterText,
                callback: function ($$v) {
                  _vm.filterText = $$v
                },
                expression: "filterText",
              },
            }),
            _c(
              "div",
              { staticClass: "marginTop10 h450 scrollbar" },
              [
                _c("el-tree", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.tabs === "1",
                      expression: "tabs === '1'",
                    },
                  ],
                  ref: "tree",
                  staticClass: "filter-tree",
                  attrs: {
                    "show-checkbox": "",
                    data: _vm.sortsData,
                    props: _vm.defaultProps,
                    "default-expand-all": "",
                    "filter-node-method": _vm.filterNode,
                  },
                  on: { "check-change": _vm.handleCheckChange },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "marginLeft20 w800 paddingTop10 flexGrow1 flex" },
          [
            _c(
              "div",
              { staticClass: "wFP flex directionColumn" },
              [
                _c(
                  "el-input",
                  {
                    staticClass: "w300",
                    attrs: { placeholder: "请输入内容", clearable: "" },
                    on: { change: _vm.queryPageStoreGoods },
                    model: {
                      value: _vm.search,
                      callback: function ($$v) {
                        _vm.search = $$v
                      },
                      expression: "search",
                    },
                  },
                  [
                    _c("el-button", {
                      attrs: { slot: "append", icon: "el-icon-search" },
                      on: { click: _vm.queryPageStoreGoods },
                      slot: "append",
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "marginTop10 bcGrey padding5 borderRadius5 paddingLeft20",
                  },
                  [
                    _vm._v("当前选中 " + _vm._s(_vm.tableData.length) + " 项 "),
                    _c(
                      "span",
                      {
                        staticClass: "pointer blue",
                        on: {
                          click: function ($event) {
                            _vm.tableData = []
                          },
                        },
                      },
                      [_vm._v("取消选择")]
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "infinite-scroll",
                        rawName: "v-infinite-scroll",
                        value: _vm.concatGoodsData,
                        expression: "concatGoodsData",
                      },
                    ],
                    staticClass:
                      "marginTop10 flexGrow1 scrollbar scrollAuto h400",
                  },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-checkbox-group",
                          {
                            model: {
                              value: _vm.tableData,
                              callback: function ($$v) {
                                _vm.tableData = $$v
                              },
                              expression: "tableData",
                            },
                          },
                          _vm._l(_vm.goodsData, function (goods) {
                            return _c(
                              "el-col",
                              { key: goods.c_barcode, attrs: { span: 6 } },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "border margin5 flex directionColumn",
                                    staticStyle: { height: "115px" },
                                  },
                                  [
                                    _c(
                                      "el-popover",
                                      {
                                        attrs: {
                                          placement: "bottom",
                                          trigger: "hover",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "w250 flex" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "w60 paddingRight10 textCenter",
                                              },
                                              [
                                                _c("el-image", {
                                                  staticClass: "w50 h50",
                                                  attrs: {
                                                    slot: "reference",
                                                    src: goods.c_master_url,
                                                    fit: "fit",
                                                  },
                                                  slot: "reference",
                                                }),
                                                _c(
                                                  "div",
                                                  { staticClass: "grey fs11" },
                                                  [_vm._v("零售价")]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "fs11" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(goods.n_price)
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c("div", [
                                              _c(
                                                "a",
                                                { staticClass: "fs11 blue" },
                                                [
                                                  _vm._v(
                                                    " " + _vm._s(goods.c_name)
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "fs11" },
                                                [
                                                  _vm._v(
                                                    _vm._s(goods.c_barcode)
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "marginTop10 grey fs11",
                                                },
                                                [
                                                  _vm._v(" 货品库存 "),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "fs11 black",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(goods.n_total)
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "grey fs11" },
                                                [
                                                  _vm._v(" 可用库存 "),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "fs11 black",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(goods.n_surplus)
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "grey fs11" },
                                                [
                                                  _vm._v(" 货品库位 "),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "fs11 black",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(goods.c_num)
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "grey fs11" },
                                                [
                                                  _vm._v(" 货品规格 "),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "fs11 black",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          goods.c_specification
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "el-checkbox",
                                          {
                                            staticClass: "marginLeft5",
                                            attrs: {
                                              slot: "reference",
                                              label: goods,
                                            },
                                            slot: "reference",
                                          },
                                          [_vm._v(_vm._s(goods.c_barcode))]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "flexGrow1" },
                                      [
                                        _c("el-image", {
                                          staticClass: "hHP wHP",
                                          attrs: {
                                            "preview-src-list": [
                                              goods.c_master_url,
                                            ],
                                            src: goods.c_master_url,
                                            fit: "fit",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "wFP paddingLeft20" },
              [
                _c(
                  "el-table",
                  {
                    ref: "multipleTable",
                    staticClass: "popper-40 wHP",
                    attrs: { data: _vm.tableData, "tooltip-effect": "dark" },
                    on: { "selection-change": _vm.handleSelectionChange },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { type: "selection", width: "40" },
                    }),
                    _c("el-table-column", {
                      attrs: { align: "center", label: "SKU", width: "50" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-popover",
                                {
                                  ref: "myPopover",
                                  attrs: {
                                    placement: "bottom-start",
                                    width: "355",
                                    trigger: "click",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "flex directionColumn" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "flex",
                                          staticStyle: {
                                            "justify-content": "space-between",
                                            "align-items": "center",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-tabs",
                                            {
                                              model: {
                                                value: _vm.activeName,
                                                callback: function ($$v) {
                                                  _vm.activeName = $$v
                                                },
                                                expression: "activeName",
                                              },
                                            },
                                            [
                                              _c("el-tab-pane", {
                                                attrs: {
                                                  label: "近似品",
                                                  name: "1",
                                                },
                                              }),
                                              _c("el-tab-pane", {
                                                attrs: {
                                                  label: "关联品",
                                                  name: "2",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a",
                                            {
                                              staticClass: "blue pointer",
                                              on: { click: _vm.handleSubmit },
                                            },
                                            [_vm._v("提交")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "h50 flex scrollAutoX",
                                          staticStyle: {
                                            "white-space": "nowrap",
                                          },
                                        },
                                        [
                                          _c("div", [_vm._v("123")]),
                                          _c("div", [_vm._v("123")]),
                                          _c("div", [_vm._v("123")]),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "pointer",
                                      attrs: { slot: "reference" },
                                      slot: "reference",
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(scope.row.c_barcode) + " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { align: "center", label: "零售价", width: "50" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [_vm._v(_vm._s(scope.row.n_price))]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { align: "center", label: "库位号", width: "60" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [_vm._v(_vm._s(scope.row.c_num))]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { align: "center", label: "数量" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm.maxNum
                                ? _c("el-input-number", {
                                    attrs: {
                                      max: scope.row.n_surplus,
                                      min: 0,
                                      "controls-position": "right",
                                    },
                                    model: {
                                      value: scope.row.num,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "num", $$v)
                                      },
                                      expression: "scope.row.num",
                                    },
                                  })
                                : _c("el-input-number", {
                                    attrs: {
                                      min: 0,
                                      "controls-position": "right",
                                    },
                                    model: {
                                      value: scope.row.num,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "num", $$v)
                                      },
                                      expression: "scope.row.num",
                                    },
                                  }),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { align: "center", label: "操作", width: "60" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("i", {
                                staticClass: "el-icon-delete pointer",
                                on: {
                                  click: function ($event) {
                                    return _vm.onDelete(scope.$index)
                                  },
                                },
                              }),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
      ]),
      _c(
        "el-row",
        { attrs: { type: "flex", justify: "end" } },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", plain: "" },
              on: {
                click: function ($event) {
                  return _vm.onCancel(true)
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
            [_vm._v("确 认")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }