import moment from "moment";
export function formatTime(value) {
  return moment(new Date(value)).format("YYYY-MM-DD HH:mm:ss");
}

export function determineExists(value) {
  if (value === null || value === undefined || value === "") {
    return false;
  } else {
    return true;
  }
}
